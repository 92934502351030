.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-Header{
  min-height: 100vh;
  background-color: #f0f2f5;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* bot css */

/* Bot.css */
.chat-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 1000;
}

.chat-header {
  display: none; /* Hide header if you don't need it */
}



/* bot css*/

/*Search bar css*/
/* SearchBar.css */
.search-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px; /* Set max-width to your desired width */
}

.suggestions-list {
  position: absolute;
  top: 100%;
  right: 0; /* Aligns the dropdown to the right */
  background-color: white;
  border: 1px solid #ccc;
  width: 20%; /* Set initial width */
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .suggestions-list {
    width: 100%; /* Make it full width on smaller screens */
    max-width: none;
  }
}


/*Search bar css */


/* Card container */
.card1 {
  height: 620px; /* Fixed height for cards */
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd; /* Optional: border for visual distinction */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: hidden; /* Ensures no content overflows the card */
  margin: 10px; /* Optional: space between cards */
}

/* Image area inside the card */
.card-img-top {
  height: 300px; /* Adjust this value based on your design */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  width: 100%; /* Make sure images fill the container width */
}

/* Content area inside the card */
.card-content1 {
  flex: 1; /* Takes up remaining space */
  padding: 16px; /* Padding inside the card */
  overflow: hidden; /* Ensures content doesn’t overflow */
}

/* Footer area inside the card (where Add to Cart button is) */
.card-footer1 {
  padding: 16px; /* Padding inside the footer */
  border-top: 1px solid #ddd; /* Optional: border to separate footer from content */
  background-color: #f9f9f9; /* Optional: background color for the footer */
  margin-top: auto; /* Pushes footer to the bottom */
}

/* Example button styling */
.card-footer1 .add-to-cart1 {
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Removes default button border */
  padding: 10px 20px; /* Button padding */
  border-radius: 4px; /* Rounded corners for button */
  cursor: pointer; /* Shows a pointer cursor on hover */
}

/* Example hover effect for button */
.card-footer1 .add-to-cart1:hover {
  background-color: #0056b3; /* Darker background color on hover */
}

/* src/CartModal.css */
.cart-modal {
  position: absolute;
  top: 60px; /* Adjust based on your navbar height */
  right: 0;
  width: 250px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cart-modal-content {
  padding: 10px;
}


.cart-button {
  position: relative;
  display: inline-block;
}

.cart-badge {
  position: absolute;
  top: 5px;  /* Adjust this value to move the badge down/up */
  right: 5px; /* Adjust this value to move the badge left/right */
  transform: translate(50%, -50%);
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
  line-height: 1;
}
/* Language button mobile */
@media (max-width: 768px) {
  .language-selector {
    margin-bottom: 0.5rem; /* Add some margin below the language selector */
  }
  
  .cart-button-container {
    margin-bottom: 0.5rem; /* Ensure the cart button also has some spacing */
  }
}
/* Language button mobile */

/* cart icon button mobile */

@media (max-width: 768px) {
  .navbar-collapse {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-end; /* Align to the right */
  }

  .cart-button-container {
    margin-bottom: 1rem; /* Add some margin below the cart button */
  }

  .language-selector {
    margin-bottom: 1rem; /* Add margin below the language selector */
  }
}

.cart-button {
  transition: background-color 0.3s, color 0.3s;
}

.cart-button.hover-active {
  background-color: #dbe1e8; /* Change to your preferred color */
  color: white; /* Change text color */
}

/* Optional: Add a hover state for the button */
.cart-button.hover-active:hover {
  background-color: #bcc4cd; /* Change to your preferred color */
}

/* cart icon button mobile */


/* Globally reset the focus outline for all form elements inside the navbar */
.navbar input:focus,
.navbar select:focus,
.navbar textarea:focus,
.navbar button:focus,
.navbar .dropdown-toggle:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 0.1px #FFFFFF; 
  border-color: #FFFFFF; 
}

/* Optional: Adjust for mobile devices only */
@media (max-width: 768px) {
  .navbar input:focus,
  .navbar select:focus,
  .navbar textarea:focus,
  .navbar button:focus,
  .navbar .dropdown-toggle:focus {
    outline: none;
    box-shadow: 0 0 0 0.1px #FFFFFF;
    border-color: #FFFFFF;
  }
}

/* Cart notification badge for mobile screens */
.cart-notification-mobile {
  position: relative;
}

.cart-notification-mobile .badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 12px;
}


.mySwiper .swiper-button-prev, 
.mySwiper .swiper-button-next {
  color: #000; /* Change the color of navigation buttons */
}

.card-slide {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.card-slide:hover {
  transform: translateY(-10px); /* Adds hover effect to the cards */
}
